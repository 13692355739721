import { template as template_8358302d0d62427f97cf814a02d3d094 } from "@ember/template-compiler";
const WelcomeHeader = template_8358302d0d62427f97cf814a02d3d094(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
