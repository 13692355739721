import { template as template_589f443c07294d6f83dfb3230e44b6c8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_589f443c07294d6f83dfb3230e44b6c8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
